// var

// phone
$phone-bp: "<=phone";
$phone-bp-land:"landscape";
$phone-bp-land-w:"<970px";


// functions
@mixin fullScreen(){
  width: 100%;
  height: 100vh;
}

@mixin text($fontSiz, $textTransform) {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: white;
  font-size: $fontSiz;
  text-transform: $textTransform;
  width: 94%;
}

@mixin pageBody() {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available !important;
  align-items: center;
  background-color:black;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@mixin closeMobButton() {
  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  right: 0;
  rotate: 45deg;
}

@mixin slideY($transY) {
  transform: translateY($transY);
  transition: transform 500ms;
}

@mixin slideX($transX) {
  transform: translateX($transX);
  transition: transform 500ms;
}

@mixin slide($transX) {
  transform: translateX($transX);
  transition: transform 500ms;
}
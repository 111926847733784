// button
button{
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    user-select: none;
  }

  // button info
  .info {
    display: flex;
    position: absolute;
    width: 100%;
    justify-self: center;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    transition: 500ms;

      p{
      @include text(1.5vmin,uppercase);
      justify-content: center;
      width: 100%;
      margin-right: 1.5%;

      @include media($phone-bp){
        font-size:4vmin;
      }
      @include media($phone-bp-land, $phone-bp-land-w){
        font-size:4vmin;
      }
        &:hover {
          transition: 100ms;
          font-weight: 500;
        }
    }
  }

  // button now
  .now{
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    position: absolute;
    width: auto;
    height: 100vh;
    transition: 500ms;
    left: 0%;
    top: 0%;

    @include media($phone-bp){
      margin-left: -3%;
      height: -webkit-fill-available !important;
    }
    @include media($phone-bp-land, $phone-bp-land-w){
      height: -webkit-fill-available !important;
    }
  
    p {
      @include text(1.5vmin,uppercase);
      user-select: none;
      width: 100%;
      text-align: center;
      transform-origin: center;
      transform: rotate(-90deg);
        &:hover {
          transition: 100ms;
          font-weight: 500;
        }
  
        @include media($phone-bp){
          font-size:4vmin;
        }
        @include media($phone-bp-land, $phone-bp-land-w){
          font-size:4vmin;
        }
    }
  }
  
  // button past
  .past{
    right: 0;
    top: 0%;
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    position: absolute;
    height: 100vh;
    transition: 500ms;

      @include media($phone-bp){
        margin-right: -3%;
        height: -webkit-fill-available !important;
      }

      @include media($phone-bp-land, $phone-bp-land-w){
        height: -webkit-fill-available !important;
      }
  
    p {
      @include text(1.5vmin,uppercase);
      user-select: none;
      width: 100%;
      text-align: center;
      transform-origin: center;
      transform: rotate(90deg);
        @include media($phone-bp){
          font-size:4vmin;
        }
        @include media($phone-bp-land, $phone-bp-land-w){
          font-size:4vmin;
        }
        &:hover {
          transition: 100ms;
          font-weight: 500;
        }
    }
  }
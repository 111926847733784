@import "./includMidea";
@import "./variables";
@import "./info";
@import "./now";
@import "./past";
@import "./INRTO";
@import "./navigation";
@import "./PROJECT";

//_________cover!!!____________
// an comment intro!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// .cover{
//   width: 100%;
//   height: 100vh;
//   background: black;
//   position: absolute;
//   top: 0;
//   left: 0;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;

//   .coming-soon{
//     // right: -40%;
//     position: relative;
//     @include text(6vmin,uppercase);
//     color: white;
//     text-align: center;
//   }
// }
//------------------------------------!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

// GLOBAL
p{
  @include text(2vmin,none);
  height: auto;
  text-indent: 2%;
  margin: 0;
  margin-top: 1%;
  &::-moz-selection { /* Code for Firefox */
    color:black;
    background:white;
  }
  &::selection {
    color:black;
    background: white;
  }
  
    @include media($phone-bp){
      font-size:5vmin;
    }
    @include media($phone-bp-land, $phone-bp-land-w){
      display: flex;
      font-size:5vmin;
    }
}

h1{
  @include text(2vmin,uppercase);
  margin-top: 3%;
  &::-moz-selection { /* Code for Firefox */
    color:black;
    background:white;
  }
   &::selection {
    color:black;
    background: white;
  }

    @include media($phone-bp){
      font-size:5vmin;
    }
    @include media($phone-bp-land, $phone-bp-land-w){
      display: flex;
      font-size:5vmin;
    }
}

img{
  margin-top: 3%;
  max-width: 94%;
  height: auto;

  user-select: none;
  pointer-events: none;
  
  background-image: url('./svg/plaseholder.svg') ;
  -webkit-background-size:fill;
  -moz-background-size: fill;
  -o-background-size: fill;
  background-size: fill;
}

// LOGO
.logo{
  transition: 500ms;
  margin: 1.5vmin;
  height: 6.5vmin;
  width: auto;
  position: absolute;
  right: 0%;
  top: 0;
  background-image:none;
  user-select: none;

    @include media($phone-bp){
      top:auto;
      bottom: 0;
      height: 12.5vmin;
    }
    @include media($phone-bp-land, $phone-bp-land-w){
      top:auto;
      bottom: 0;
      height: 12.5vmin;
    }
}

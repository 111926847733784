.project{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    height: -webkit-fill-available !important;
    z-index: -1;
    overflow: scroll;
    top: 0;
    left: 0;
    margin: 0;
    cursor: grab;
    // cursor: url(../public/static/img/drag.png)1 1, auto;
    
    -ms-overflow-style: none;
    scrollbar-width: none;

    touch-action: manipulation;
    overscroll-behavior: none;

      &:active{
        cursor: grabbing;
      }
      &::-webkit-scrollbar {
        display: none;
      }

  }
  
  .lesia-kh-ex-grid-warper{
    top: 0;
    left: 0;
    width:0px;
    height:0px;
   
    position: absolute;
  
    display: grid;
    grid-template-columns: repeat(32, auto);
    grid-template-rows: repeat(32, auto);
    grid-gap: 0px;
    gap: 0px;
    margin: 0%;
    padding: 0;

    -ms-overflow-style: none;
    scrollbar-width: none;

    // pointer-events: none;

      &::-webkit-scrollbar {
        display: none;
      }
      &::after {
        display: none;
      }

      img{
        // width:100%;
        height: 100%;
        min-width: 100%;
        min-height: 100%;

        margin: 0%;
        padding: 0%;
        pointer-events: none;
        user-select: none;

        // object-fit: cover;
        // position: relative;

        object-fit:fill;

        background-image: url('./svg/bgProject.svg') ;
        -webkit-background-size:fill;
        -moz-background-size: fill;
        -o-background-size: fill;
        background-size: fill;
      }
  }
//page NOW
//close button NOW for mob
.now-close-button{
    @include closeMobButton();

        @include media($phone-bp){
        display: flex;
            img{
                background-image:none;
            }
        }
        @include media($phone-bp-land, $phone-bp-land-w){
            display: flex;
            img{
                background-image:none;
            }
          }
}

// body page NOW
.now-box{
    @include pageBody();
    cursor: url(../public/static/img/cusorDown.png)1 1, auto;
        &::-webkit-scrollbar {
        display: none;
        }
}

//svide NOW animation box
.slide-now{
    position: absolute;
    width: 50%;
    height: 100vh;
    background-color: black;
    
        @include media($phone-bp){
        width: 100%;
        height: 100vh;
        z-index: 1;
        // height: -webkit-fill-available !important;
        }
        @include media($phone-bp-land, $phone-bp-land-w){
            width: 100%;
            height: 100vh;
            z-index: 1;
          }
}

//svide NOW animation rulse
.slide-now-enter {
    @include slide(-50%);

        @include media($phone-bp){
            transform: translateX(-100%);
        }
        @include media($phone-bp-land, $phone-bp-land-w){
            transform: translateX(-100%);
          }
}
.slide-now-enter-active {
    @include slide(0%);
}
.slide-now-enter-done {
    @include slide(0%);
}
.slide-now-exit {
    @include slide(0%);
}
.slide-now-exit-active {
    @include slide(-50%);

        @include media($phone-bp){
            transform: translateX(-100%);
        }

        @include media($phone-bp-land, $phone-bp-land-w){
            transform: translateX(-100%);
          }
}
.slide-now-exit-done {
    @include slide(-50%);

        @include media($phone-bp){
            transform: translateX(-100%);
        }
        @include media($phone-bp-land, $phone-bp-land-w){
            transform: translateX(-100%);
          }
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  overflow: hidden;
  overscroll-behavior: none;
  
  -ms-overflow-style: none; 
  scrollbar-width: none;

  touch-action: manipulation;
}
body::-webkit-scrollbar {
  display: none;
}

.App::-webkit-scrollbar {
  display: none;
}

//page PAST
//close button PAST for mob
.past-close-bution{
    @include closeMobButton();

        @include media($phone-bp){
            display: flex;
            img{
                background-image:none;
            }
        }
        @include media($phone-bp-land, $phone-bp-land-w){
            display: flex;
            img{
                background-image:none;
            }
        }
        
}

// body page PAST
.past-box{
    @include pageBody();
    z-index: 100;
    cursor: url(../public/static/img/cusorDown.png)1 1, auto;
        &::-webkit-scrollbar {
            display: none;
        }
}

  //svide PAST animation box
.slide-past{
    width: 50%;
    height: 100vh;
    position: absolute;
    background-color: rgb(0, 0, 0);
    z-index: 10;
    overflow: hidden;

        @include media($phone-bp){
            width: 100%;
            height: 100vh;
            // height: -webkit-fill-available !important;
        }
        @include media($phone-bp-land, $phone-bp-land-w){
            width: 100%;
            height: 100vh;
        }
}

//svide PAST animation rulse
.slide-past-enter {
    @include slideX(100%);
}
.slide-past-enter-active {
    @include slideX(50%);

        @include media($phone-bp){
            transform: translateX(0%);
        }
        @include media($phone-bp-land, $phone-bp-land-w){
            transform: translateX(0%);
          }
}
.slide-past-enter-done {
    @include slideX(50%);

        @include media($phone-bp){
            transform: translateX(0%);
        }
        @include media($phone-bp-land, $phone-bp-land-w){
            transform: translateX(0%);
          }
}
.slide-past-exit {
    @include slideX(50%);
    
        @include media($phone-bp){
            transform: translateX(0%);
        }
        @include media($phone-bp-land, $phone-bp-land-w){
            transform: translateX(0%);
          }
}
.slide-past-exit-active {
    @include slideX(100%);
}
.slide-past-exit-done {
    @include slideX(100%);
}
// inrto
.intro-drag-box{
  @include fullScreen();
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0);
  margin: 0;
  overflow: hidden;

  cursor: pointer;
  user-select: none;

  @include media($phone-bp){
    height: 100vh;
    height: -webkit-fill-available !important;
  }
  @include media($phone-bp-land, $phone-bp-land-w){
    height: 100vh;
    height: -webkit-fill-available !important;
  }
    img{
      position: absolute;
      width: 12%;
      z-index: 2;
      margin: 0;
      background-image:none;
      user-select: none;

      @include media($phone-bp){
        width: 40%;
      }
      @include media($phone-bp-land, $phone-bp-land-w){
        width: 20%;
      }
    }

    p{
      @include text(1.5vmin,uppercase);
      position: absolute;
      margin: 0;
      width: auto;
      user-select: none;
      
      @include media($phone-bp){
        font-size:4vmin;
      }
      @include media($phone-bp-land, $phone-bp-land-w){
        font-size:4vmin;
      }
    }
}
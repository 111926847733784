.info-close-button {
  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  right: 0;
  rotate: 45deg;
}
@media (max-width: 425px) {
  .info-close-button {
    display: flex;
  }
  .info-close-button img {
    background-image: none;
  }
}
@media (orientation: landscape) and (max-width: 969px) {
  .info-close-button {
    display: flex;
  }
  .info-close-button img {
    background-image: none;
  }
}

.info-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available !important;
  align-items: center;
  background-color: black;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 50vh;
  justify-content: space-between;
}
.info-box::-webkit-scrollbar {
  display: none;
}

.info-text-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  margin-top: 2%;
}

.info-contact-box {
  display: flex;
  flex-direction: row;
  justify-self: center;
  justify-content: space-between;
  align-items: flex-end;
  width: 94%;
  height: auto;
  margin-bottom: 1%;
}
.info-contact-box a {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 2vmin;
  cursor: pointer;
  text-indent: 2%;
  margin: 0%;
  text-decoration: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.info-contact-box a:hover {
  transition: 100ms;
  font-weight: 500;
}
@media (max-width: 425px) {
  .info-contact-box a {
    font-size: 5vmin;
  }
}
@media (orientation: landscape) and (max-width: 969px) {
  .info-contact-box a {
    font-size: 5vmin;
  }
}
@media (max-width: 425px) {
  .info-contact-box {
    margin-top: 6%;
    margin-bottom: 20%;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (orientation: landscape) and (max-width: 969px) {
  .info-contact-box {
    margin-top: 6%;
    margin-bottom: 6%;
    flex-direction: column;
    align-items: flex-start;
  }
}

.slide-info {
  width: 100%;
  height: 50vh;
  background-color: black;
  z-index: 100;
}
@media (max-width: 425px) {
  .slide-info {
    height: 100vh;
  }
}
@media (orientation: landscape) and (max-width: 969px) {
  .slide-info {
    height: 100vh;
  }
}

.slide-info-enter {
  transform: translateY(-100%);
  transition: transform 500ms;
}

.slide-info-enter-active {
  transform: translateY(0%);
  transition: transform 500ms;
}

.slide-info-enter-done {
  transform: translateY(0%);
  transition: transform 500ms;
}

.slide-info-exit {
  transform: translateY(0%);
  transition: transform 500ms;
}

.slide-info-exit-active {
  transform: translateY(-100%);
  transition: transform 500ms;
}

.slide-info-exit-done {
  transform: translateY(-100%);
  transition: transform 500ms;
}

.now-close-button {
  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  right: 0;
  rotate: 45deg;
}
@media (max-width: 425px) {
  .now-close-button {
    display: flex;
  }
  .now-close-button img {
    background-image: none;
  }
}
@media (orientation: landscape) and (max-width: 969px) {
  .now-close-button {
    display: flex;
  }
  .now-close-button img {
    background-image: none;
  }
}

.now-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available !important;
  align-items: center;
  background-color: black;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  cursor: url(../public/static/img/cusorDown.png) 1 1, auto;
}
.now-box::-webkit-scrollbar {
  display: none;
}

.slide-now {
  position: absolute;
  width: 50%;
  height: 100vh;
  background-color: black;
}
@media (max-width: 425px) {
  .slide-now {
    width: 100%;
    height: 100vh;
    z-index: 1;
  }
}
@media (orientation: landscape) and (max-width: 969px) {
  .slide-now {
    width: 100%;
    height: 100vh;
    z-index: 1;
  }
}

.slide-now-enter {
  transform: translateX(-50%);
  transition: transform 500ms;
}
@media (max-width: 425px) {
  .slide-now-enter {
    transform: translateX(-100%);
  }
}
@media (orientation: landscape) and (max-width: 969px) {
  .slide-now-enter {
    transform: translateX(-100%);
  }
}

.slide-now-enter-active {
  transform: translateX(0%);
  transition: transform 500ms;
}

.slide-now-enter-done {
  transform: translateX(0%);
  transition: transform 500ms;
}

.slide-now-exit {
  transform: translateX(0%);
  transition: transform 500ms;
}

.slide-now-exit-active {
  transform: translateX(-50%);
  transition: transform 500ms;
}
@media (max-width: 425px) {
  .slide-now-exit-active {
    transform: translateX(-100%);
  }
}
@media (orientation: landscape) and (max-width: 969px) {
  .slide-now-exit-active {
    transform: translateX(-100%);
  }
}

.slide-now-exit-done {
  transform: translateX(-50%);
  transition: transform 500ms;
}
@media (max-width: 425px) {
  .slide-now-exit-done {
    transform: translateX(-100%);
  }
}
@media (orientation: landscape) and (max-width: 969px) {
  .slide-now-exit-done {
    transform: translateX(-100%);
  }
}

.past-close-bution {
  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  right: 0;
  rotate: 45deg;
}
@media (max-width: 425px) {
  .past-close-bution {
    display: flex;
  }
  .past-close-bution img {
    background-image: none;
  }
}
@media (orientation: landscape) and (max-width: 969px) {
  .past-close-bution {
    display: flex;
  }
  .past-close-bution img {
    background-image: none;
  }
}

.past-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available !important;
  align-items: center;
  background-color: black;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 100;
  cursor: url(../public/static/img/cusorDown.png) 1 1, auto;
}
.past-box::-webkit-scrollbar {
  display: none;
}

.slide-past {
  width: 50%;
  height: 100vh;
  position: absolute;
  background-color: rgb(0, 0, 0);
  z-index: 10;
  overflow: hidden;
}
@media (max-width: 425px) {
  .slide-past {
    width: 100%;
    height: 100vh;
  }
}
@media (orientation: landscape) and (max-width: 969px) {
  .slide-past {
    width: 100%;
    height: 100vh;
  }
}

.slide-past-enter {
  transform: translateX(100%);
  transition: transform 500ms;
}

.slide-past-enter-active {
  transform: translateX(50%);
  transition: transform 500ms;
}
@media (max-width: 425px) {
  .slide-past-enter-active {
    transform: translateX(0%);
  }
}
@media (orientation: landscape) and (max-width: 969px) {
  .slide-past-enter-active {
    transform: translateX(0%);
  }
}

.slide-past-enter-done {
  transform: translateX(50%);
  transition: transform 500ms;
}
@media (max-width: 425px) {
  .slide-past-enter-done {
    transform: translateX(0%);
  }
}
@media (orientation: landscape) and (max-width: 969px) {
  .slide-past-enter-done {
    transform: translateX(0%);
  }
}

.slide-past-exit {
  transform: translateX(50%);
  transition: transform 500ms;
}
@media (max-width: 425px) {
  .slide-past-exit {
    transform: translateX(0%);
  }
}
@media (orientation: landscape) and (max-width: 969px) {
  .slide-past-exit {
    transform: translateX(0%);
  }
}

.slide-past-exit-active {
  transform: translateX(100%);
  transition: transform 500ms;
}

.slide-past-exit-done {
  transform: translateX(100%);
  transition: transform 500ms;
}

.intro-drag-box {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0);
  margin: 0;
  overflow: hidden;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
@media (max-width: 425px) {
  .intro-drag-box {
    height: 100vh;
    height: -webkit-fill-available !important;
  }
}
@media (orientation: landscape) and (max-width: 969px) {
  .intro-drag-box {
    height: 100vh;
    height: -webkit-fill-available !important;
  }
}
.intro-drag-box img {
  position: absolute;
  width: 12%;
  z-index: 2;
  margin: 0;
  background-image: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
@media (max-width: 425px) {
  .intro-drag-box img {
    width: 40%;
  }
}
@media (orientation: landscape) and (max-width: 969px) {
  .intro-drag-box img {
    width: 20%;
  }
}
.intro-drag-box p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 1.5vmin;
  text-transform: uppercase;
  width: 94%;
  position: absolute;
  margin: 0;
  width: auto;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
@media (max-width: 425px) {
  .intro-drag-box p {
    font-size: 4vmin;
  }
}
@media (orientation: landscape) and (max-width: 969px) {
  .intro-drag-box p {
    font-size: 4vmin;
  }
}

button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.info {
  display: flex;
  position: absolute;
  width: 100%;
  justify-self: center;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  transition: 500ms;
}
.info p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 1.5vmin;
  text-transform: uppercase;
  width: 94%;
  justify-content: center;
  width: 100%;
  margin-right: 1.5%;
}
@media (max-width: 425px) {
  .info p {
    font-size: 4vmin;
  }
}
@media (orientation: landscape) and (max-width: 969px) {
  .info p {
    font-size: 4vmin;
  }
}
.info p:hover {
  transition: 100ms;
  font-weight: 500;
}

.now {
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  position: absolute;
  width: auto;
  height: 100vh;
  transition: 500ms;
  left: 0%;
  top: 0%;
}
@media (max-width: 425px) {
  .now {
    margin-left: -3%;
    height: -webkit-fill-available !important;
  }
}
@media (orientation: landscape) and (max-width: 969px) {
  .now {
    height: -webkit-fill-available !important;
  }
}
.now p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 1.5vmin;
  text-transform: uppercase;
  width: 94%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
  text-align: center;
  transform-origin: center;
  transform: rotate(-90deg);
}
.now p:hover {
  transition: 100ms;
  font-weight: 500;
}
@media (max-width: 425px) {
  .now p {
    font-size: 4vmin;
  }
}
@media (orientation: landscape) and (max-width: 969px) {
  .now p {
    font-size: 4vmin;
  }
}

.past {
  right: 0;
  top: 0%;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  position: absolute;
  height: 100vh;
  transition: 500ms;
}
@media (max-width: 425px) {
  .past {
    margin-right: -3%;
    height: -webkit-fill-available !important;
  }
}
@media (orientation: landscape) and (max-width: 969px) {
  .past {
    height: -webkit-fill-available !important;
  }
}
.past p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 1.5vmin;
  text-transform: uppercase;
  width: 94%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
  text-align: center;
  transform-origin: center;
  transform: rotate(90deg);
}
@media (max-width: 425px) {
  .past p {
    font-size: 4vmin;
  }
}
@media (orientation: landscape) and (max-width: 969px) {
  .past p {
    font-size: 4vmin;
  }
}
.past p:hover {
  transition: 100ms;
  font-weight: 500;
}

.project {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  height: -webkit-fill-available !important;
  z-index: -1;
  overflow: scroll;
  top: 0;
  left: 0;
  margin: 0;
  cursor: -webkit-grab;
  cursor: grab;
  -ms-overflow-style: none;
  scrollbar-width: none;
  touch-action: manipulation;
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
}
.project:active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.project::-webkit-scrollbar {
  display: none;
}

.lesia-kh-ex-grid-warper {
  top: 0;
  left: 0;
  width: 0px;
  height: 0px;
  position: absolute;
  display: grid;
  grid-template-columns: repeat(32, auto);
  grid-template-rows: repeat(32, auto);
  grid-gap: 0px;
  gap: 0px;
  margin: 0%;
  padding: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.lesia-kh-ex-grid-warper::-webkit-scrollbar {
  display: none;
}
.lesia-kh-ex-grid-warper::after {
  display: none;
}
.lesia-kh-ex-grid-warper img {
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  margin: 0%;
  padding: 0%;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -o-object-fit: fill;
     object-fit: fill;
  background-image: url("./svg/bgProject.svg");
  background-size: fill;
}

p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 2vmin;
  text-transform: none;
  width: 94%;
  height: auto;
  text-indent: 2%;
  margin: 0;
  margin-top: 1%;
}
p::-moz-selection { /* Code for Firefox */
  color: black;
  background: white;
}
p::selection {
  color: black;
  background: white;
}
@media (max-width: 425px) {
  p {
    font-size: 5vmin;
  }
}
@media (orientation: landscape) and (max-width: 969px) {
  p {
    display: flex;
    font-size: 5vmin;
  }
}

h1 {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 2vmin;
  text-transform: uppercase;
  width: 94%;
  margin-top: 3%;
}
h1::-moz-selection { /* Code for Firefox */
  color: black;
  background: white;
}
h1::selection {
  color: black;
  background: white;
}
@media (max-width: 425px) {
  h1 {
    font-size: 5vmin;
  }
}
@media (orientation: landscape) and (max-width: 969px) {
  h1 {
    display: flex;
    font-size: 5vmin;
  }
}

img {
  margin-top: 3%;
  max-width: 94%;
  height: auto;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  background-image: url("./svg/plaseholder.svg");
  background-size: fill;
}

.logo {
  transition: 500ms;
  margin: 1.5vmin;
  height: 6.5vmin;
  width: auto;
  position: absolute;
  right: 0%;
  top: 0;
  background-image: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
@media (max-width: 425px) {
  .logo {
    top: auto;
    bottom: 0;
    height: 12.5vmin;
  }
}
@media (orientation: landscape) and (max-width: 969px) {
  .logo {
    top: auto;
    bottom: 0;
    height: 12.5vmin;
  }
}/*# sourceMappingURL=App.css.map */
//page INFO
//close button INFO for mob
.info-close-button {
    @include closeMobButton();

        @include media($phone-bp){
            display: flex;
            img{
                background-image:none;
            }
        }
        @include media($phone-bp-land, $phone-bp-land-w){
            display: flex;
            img{
                background-image:none;
            }
     }
       
}
// body page INFO
.info-box {
    @include pageBody();
    height: 50vh;
    justify-content: space-between;
        &::-webkit-scrollbar {
        display: none;
        }
}

//page structure INFO
.info-text-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 2%;
}
  
.info-contact-box {
    display: flex;
    flex-direction: row;
    justify-self: center;
    justify-content: space-between;
    align-items: flex-end;
    width: 94%;
    height: auto;
    margin-bottom: 1%;
        a {
            font-family: "Inter", sans-serif;
            font-weight: 400;
            color: white;
            font-size: 2vmin;
            cursor: pointer;
            text-indent: 2%;
            margin: 0%;
            text-decoration: none;
            user-select: none;
                &:hover {
                transition: 100ms;
                font-weight: 500;
                }

            @include media($phone-bp){
            font-size: 5vmin;
            }
            @include media($phone-bp-land, $phone-bp-land-w){
                font-size: 5vmin;
              }
        }

    @include media($phone-bp){
        margin-top: 6%;
        margin-bottom: 20%;
        flex-direction: column;
        align-items: flex-start;
    }
    @include media($phone-bp-land, $phone-bp-land-w){
             margin-top: 6%;
        margin-bottom: 6%;
        flex-direction: column;
        align-items: flex-start;
      }
}

  //svide INFO animation box
.slide-info {
    width: 100%;
    height: 50vh;
    background-color: black;
    z-index: 100;

        @include media($phone-bp){
            height: 100vh;
        // height: -webkit-fill-available !important;
        }
        @include media($phone-bp-land, $phone-bp-land-w){
            height: 100vh;
          }
}
//svide INFO animation rulse
.slide-info-enter {
    @include slideY(-100%);
}
.slide-info-enter-active {
    @include slideY(0%);
}
.slide-info-enter-done {
    @include slideY(0%);
}
.slide-info-exit {
    @include slideY(0%);
}
.slide-info-exit-active {
    @include slideY(-100%);
}
.slide-info-exit-done {
    @include slideY(-100%);
}
